var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { ref: "logIn", staticClass: "log" },
    [
      _c(
        "div",
        { ref: "form", staticClass: "form-area" },
        [
          _c(
            "el-form",
            {
              ref: "formInline",
              staticClass: "demo-form-inline",
              attrs: {
                model: _vm.form,
                rules: _vm.formRules,
                "label-position": "left",
                "label-width": "82px"
              }
            },
            [
              _c(
                "el-form-item",
                {
                  staticStyle: { "margin-right": "20px" },
                  attrs: { label: "选择时间：", prop: "time" }
                },
                [
                  _c("el-date-picker", {
                    attrs: {
                      cellClassName: "timeRangePicker",
                      type: "daterange",
                      align: "right",
                      "range-separator": "至",
                      "start-placeholder": "开始日期",
                      "end-placeholder": "结束日期",
                      "picker-options": _vm.pickerOptions,
                      "value-format": "timestamp"
                    },
                    model: {
                      value: _vm.form.time,
                      callback: function($$v) {
                        _vm.$set(_vm.form, "time", $$v)
                      },
                      expression: "form.time"
                    }
                  })
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "选择企业：" } },
                [
                  _c("companySelectForSearch", {
                    ref: "companySelectForSearch",
                    attrs: { searchable: true },
                    on: { getValue: _vm.getGroupIds }
                  })
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "账号：" } },
                [
                  _c("el-input", {
                    attrs: { placeholder: "请输入" },
                    model: {
                      value: _vm.form.loginName,
                      callback: function($$v) {
                        _vm.$set(_vm.form, "loginName", $$v)
                      },
                      expression: "form.loginName"
                    }
                  })
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "用户名称：" } },
                [
                  _c("el-input", {
                    attrs: { placeholder: "请输入" },
                    model: {
                      value: _vm.form.userName,
                      callback: function($$v) {
                        _vm.$set(_vm.form, "userName", $$v)
                      },
                      expression: "form.userName"
                    }
                  })
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "登录终端：" } },
                [
                  _c(
                    "el-select",
                    {
                      attrs: { clearable: "", placeholder: "" },
                      model: {
                        value: _vm.form.loginTerminal,
                        callback: function($$v) {
                          _vm.$set(_vm.form, "loginTerminal", $$v)
                        },
                        expression: "form.loginTerminal"
                      }
                    },
                    _vm._l(_vm.loginTerminalList, function(item) {
                      return _c("el-option", {
                        key: item.dictCode,
                        attrs: { label: item.dictValue, value: item.dictCode }
                      })
                    }),
                    1
                  )
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { "label-width": "10px" } },
                [
                  _c(
                    "el-button",
                    {
                      attrs: {
                        type: "primary",
                        size: "small",
                        loading: _vm.searchLoading
                      },
                      on: {
                        click: function($event) {
                          return _vm.onSearch()
                        }
                      }
                    },
                    [_vm._v("查询")]
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _c(
        "el-table",
        { attrs: { data: _vm.tableData, stripe: "", height: _vm.tableHeight } },
        [
          _c("el-table-column", { attrs: { type: "index", label: "序号" } }),
          _c("el-table-column", {
            attrs: { prop: "userName", label: "用户名称" }
          }),
          _c("el-table-column", {
            attrs: {
              prop: "companyNames",
              label: "所属公司",
              formatter: _vm.formateCompany,
              "show-overflow-tooltip": true
            }
          }),
          _c("el-table-column", {
            attrs: { prop: "loginName", label: "账号" }
          }),
          _c("el-table-column", {
            attrs: {
              prop: "loginTime",
              label: "登录时间",
              width: "200",
              "show-overflow-tooltip": true
            }
          }),
          _c("el-table-column", {
            attrs: {
              prop: "logoutTime",
              label: "登出时间",
              width: "200",
              "show-overflow-tooltip": true
            }
          }),
          _c("el-table-column", {
            attrs: {
              prop: "duration",
              label: "登录时长(分)",
              "show-overflow-tooltip": true,
              formatter: _vm.formateTime
            }
          }),
          _c("el-table-column", {
            attrs: {
              prop: "loginTerminal",
              label: "登录终端",
              "show-overflow-tooltip": "",
              formatter: _vm.formateType
            }
          })
        ],
        1
      ),
      _c(
        "div",
        { ref: "pagination", staticClass: "pagination" },
        [
          _c("el-pagination", {
            attrs: {
              background: "",
              layout: "total, sizes, prev, pager, next, jumper",
              total: _vm.total,
              "page-size": _vm.form.pageSize,
              "current-page": _vm.form.currentPage
            },
            on: {
              "size-change": _vm.onSizeChange,
              "current-change": _vm.onCurrentChange
            }
          })
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }