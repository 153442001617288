<template>
  <div class="log" ref="logIn">
    <div class="form-area" ref="form">
      <el-form
        :model="form"
        ref="formInline"
        :rules="formRules"
        class="demo-form-inline"
        label-position="left"
        label-width="82px"
      >
        <!-- 选择时间： -->
        <el-form-item label="选择时间：" prop="time" style="margin-right: 20px">
          <el-date-picker
            cellClassName="timeRangePicker"
            v-model="form.time"
            type="daterange"
            align="right"
            range-separator="至"
            start-placeholder="开始日期"
            end-placeholder="结束日期"
            :picker-options="pickerOptions"
            value-format="timestamp"
          ></el-date-picker>
        </el-form-item>
        <el-form-item label="选择企业：">
          <companySelectForSearch
            ref="companySelectForSearch"
            :searchable="true"
            @getValue="getGroupIds"
          />
        </el-form-item>
        <el-form-item label="账号：">
          <el-input v-model="form.loginName" placeholder="请输入"></el-input>
        </el-form-item>
        <el-form-item label="用户名称：">
          <el-input v-model="form.userName" placeholder="请输入"></el-input>
        </el-form-item>
        <el-form-item label="登录终端：">
          <el-select v-model="form.loginTerminal" clearable placeholder>
            <el-option
              v-for="item in loginTerminalList"
              :label="item.dictValue"
              :value="item.dictCode"
              :key="item.dictCode"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label-width="10px">
          <el-button
            type="primary"
            size="small"
            :loading="searchLoading"
            @click="onSearch()"
            >查询</el-button
          >
        </el-form-item>
      </el-form>
    </div>
    <el-table :data="tableData" stripe :height="tableHeight">
      <el-table-column type="index" label="序号"></el-table-column>
      <el-table-column prop="userName" label="用户名称"></el-table-column>
      <el-table-column
        prop="companyNames"
        label="所属公司"
        :formatter="formateCompany"
        :show-overflow-tooltip="true"
      ></el-table-column>
      <el-table-column prop="loginName" label="账号"></el-table-column>
      <el-table-column
        prop="loginTime"
        label="登录时间"
        width="200"
        :show-overflow-tooltip="true"
      >
      </el-table-column>
      <el-table-column
        prop="logoutTime"
        label="登出时间"
        width="200"
        :show-overflow-tooltip="true"
      ></el-table-column>
      <el-table-column
        prop="duration"
        label="登录时长(分)"
        :show-overflow-tooltip="true"
        :formatter="formateTime"
      ></el-table-column>
      <el-table-column
        prop="loginTerminal"
        label="登录终端"
        show-overflow-tooltip
        :formatter="formateType"
      ></el-table-column>
    </el-table>
    <div class="pagination" ref="pagination">
      <el-pagination
        background
        layout="total, sizes, prev, pager, next, jumper"
        :total="total"
        :page-size="form.pageSize"
        :current-page="form.currentPage"
        @size-change="onSizeChange"
        @current-change="onCurrentChange"
      ></el-pagination>
    </div>
  </div>
</template>

<script>
import companySelectForSearch from '@/components/treeSelect/companySelectForSearch.vue'

import { formatDay, checkTimeLimitDay, getRangeDay } from '@/common/utils/index'
import { queryUserLoginHistory } from '@/api/lib/api.js'

export default {
  name: 'logIn',
  components: { companySelectForSearch },
  data () {
    const validateDate1 = (rule, value, callback) => {
      if (value) {
        if (!checkTimeLimitDay(value[0], value[1], this.timeLimit)) {
          callback(new Error(`只能查询${this.timeLimit}天内的数据`))
        } else {
          callback()
        }
      } else {
        callback()
      }
    }
    return {
      tableHeight: 0,
      total: 0,
      tableData: [],
      timeLimit: 60,
      form: {
        time: null,
        companyId: null,
        loginName: null,
        userName: null,
        currentPage: 1,
        pageSize: 10,
        loginTerminal: null,//登录终端：0-管理后台 1-小程序 2-移动端 3-钉钉小程序
      },
      loginTerminalList: [
        {
          dictValue: '管理后台',
          dictCode: 0,
        },
        {
          dictValue: '小程序',
          dictCode: 1,
        },
        {
          dictValue: '移动端',
          dictCode: 2,
        },
        {
          dictValue: '钉钉小程序',
          dictCode: 3,
        },
      ],
      pickerOptions: {
        shortcuts: [
          {
            text: '最近一周',
            onClick (picker) {
              const end = getRangeDay()[1]
              const start = getRangeDay()[1] - 3600 * 1000 * 24 * 7
              picker.$emit('pick', [start, end])
            }
          },
          {
            text: '最近一个月',
            onClick (picker) {
              const end = getRangeDay()[1]
              const start = getRangeDay()[1] - 3600 * 1000 * 24 * 30
              picker.$emit('pick', [start, end])
            }
          }
        ]
      },
      formRules: {
        time: [{ validator: validateDate1, trigger: 'change' }]
      },
      searchLoading: false
    }
  },
  methods: {
    // 计算表格高度
    computeHeight () {
      const wholeHeight = this.$refs.logIn.clientHeight
      const formHeight = this.$refs.form.clientHeight
      const paginationHeight = this.$refs.pagination.clientHeight
      this.tableHeight =
        wholeHeight - 16 - formHeight - paginationHeight - 10 - 16
    },
    // 点击查询
    onSearch () {
      this.searchLoading = true
      this.form.currentPage = 1
      this.form.pageSize = 10
      this.getListByField()
    },
    // 获取车组数据
    getGroupIds (val) {
      this.form.companyId = val.length > 0 ? val[0] : null
    },

    //获取数据
    getListByField () {
      this.$refs.formInline.validate((valid) => {
        if (valid) {
          let data = { ...this.form }
          if (data.time) {
            data.startDay = formatDay(this.form.time[0])
            data.endDay = formatDay(this.form.time[1])
          } else {
            data.startDay = null
            data.endDay = null
          }
          delete data.time
          queryUserLoginHistory(data)
            .then((res) => {
              if (res.code === 1000) {
                this.tableData = res.data.list
                this.total = res.data.total
              } else {
                this.tableData = []
                this.total = 0
                this.$message.error(res.msg)
              }
              this.searchLoading = false
            })
            .catch(() => {
              this.searchLoading = false
            })
        } else {
          this.searchLoading = false
        }
      })
    },
    // 当前页码改变
    onCurrentChange (page) {
      this.form.currentPage = page
      this.getListByField()
    },

    // 当前条数改变
    onSizeChange (size) {
      this.form.pageSize = size
      this.getListByField()
    },

    //时间显示
    formateTime (row, column, cellValue) {
      return cellValue == null || cellValue == ''
        ? '-'
        : Math.ceil(cellValue / 60)
    },
    //登录终端解析
    formateType (row, column, cellValue) {
      return cellValue == 0 ? '管理后台' : cellValue == 1 ? '小程序' : cellValue == 2 ? '移动端' : cellValue == 3 ? '钉钉小程序' : '-'
    },
    //时间显示
    formateCompany (row, column, cellValue) {
      return cellValue == null || cellValue == '' ? '-' : cellValue.join(',')
    }
  },
  created () { },
  mounted () {
    this.$nextTick(() => {
      this.computeHeight()
      this.onSearch()
      window.addEventListener('resize', this.computeHeight, false)
    })
  },
  destroyed () {
    window.removeEventListener('resize', this.computeHeight)
  }
}
</script>

<style lang="scss" scoped>
.log {
  height: 100%;
  width: 100%;
  border-radius: 4px;
  padding: 16px 20px;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}
.theme-project-resourse {
  .log {
    background: #ffffff;
    box-shadow: 0 0 4px 0 rgba($color: #343434, $alpha: 0.1);
  }
}
</style>
